<template>
  <div class="add-parking-basic">
    <el-form ref="form" :model="model" :rules="rules" label-width="200px" size="small">

      <el-row>
        <el-col :span="12">
          <el-form-item label="车场名称" prop="name" required>
            <el-input v-model="model.name" placeholder="车场名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车场简称" prop="shortName" required>
            <el-input v-model="model.shortName" placeholder="车场简称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="城区" prop="regionId" required>
            <el-cascader ref="regionId" v-model="model.regionId" :clearable="true" :filterable="true" :props="props"
              :show-all-levels="false" placeholder="请选择城区" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="详细地址" prop="address" required>
            <el-input v-model="model.address" placeholder="详细地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="车场经纬度" prop="parkingLocation" required>
            <Location v-model="model.parkingLocation" @makesure="onLocationMakeSure" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车场类型" prop="parkingLotType" required>
            <el-select v-model="model.parkingLotType" placeholder="车场类型" style="width: 100%">
              <el-option label="路内" value="1"></el-option>
              <el-option label="路外" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="泊位个数" prop="parkingspaceNumber" required>
            <el-input v-model="model.parkingspaceNumber" placeholder="泊位个数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="无出场记录" prop="noDriveOut" required>
            <div style="display: flex;align-items: center;">
              <el-tooltip effect="light" placement="right" style="margin-right: 5px;">
                <div slot="content" class="tool-font">
                  车辆历史订单没有出场记录情况<br/>
                  下，是否计入欠费
                </div>
                <i class="el-icon-question" style="color: #0768FD;"></i>
              </el-tooltip>
              <el-select v-model="model.noDriveOut" placeholder="无出场记录" style="width: 100%">
                <el-option label="不计费" value="0"></el-option>
                <el-option label="计费" value="1"></el-option>
              </el-select>
            </div>

          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="历史欠费离场" prop="unPayDriveOut" required>
            <el-select v-model="model.unPayDriveOut" placeholder="历史欠费离场" style="width: 100%">
              <el-option label="允许" value="0"></el-option>
              <el-option label="不允许" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主子车入场" prop="vehicleDriveIn" required>
            <div style="display: flex;align-items: center;">
              <el-tooltip effect="light" placement="right" style="margin-right: 5px;">
                <div slot="content" class="tool-font">
                  长租情况下，一位多车，第二辆<br/>
                  车入场以临停收费标准收费
                </div>
                <i class="el-icon-question" style="color: #0768FD;"></i>
              </el-tooltip>
              <el-select v-model="model.vehicleDriveIn" placeholder="主子车入场" style="width: 100%">
                <el-option label="开启" value="0"></el-option>
              <el-option label="关闭" value="1"></el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="临停入场" prop="motionType" required>
            <el-select v-model="model.motionType" placeholder="临停入场" style="width: 100%">
              <el-option label="允许" value="0"></el-option>
              <el-option label="不允许" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="无入场记录" prop="noDriveIn" required>
            <div style="display: flex;align-items: center;">
              <el-tooltip effect="light" placement="right" style="margin-right: 5px;">
                <div slot="content" class="tool-font">
                  上次车辆历史订单没有入场记录情况<br/>
                  下，是否计入欠费
                </div>
                <i class="el-icon-question" style="color: #0768FD;"></i>
              </el-tooltip>
              <el-select v-model="model.noDriveIn" placeholder="无入场记录" style="width: 100%">
                <el-option label="不计费" value="0"></el-option>
                <el-option label="计费" value="1"></el-option>
              </el-select>
            </div>

          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="车场平面图" prop="plandefaultAttachmentId" required>
            <el-upload ref="graphImageUpload" :file-list="graphFileList" :http-request="doGraphImageUpload"
              :on-remove="onGraphImageRemove" action="" list-type="picture-card">
              <span>点击上传</span>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车场实景图" prop="sceneDefaultAttachmentId" required>
            <el-upload ref="realImageUpload" :file-list="realFileList" :http-request="doRealImageUpload"
              :on-remove="onRealImageRemove" action="" list-type="picture-card">
              <span>点击上传</span>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <!--      <easy-card title="企业信息">-->
      <!--        <el-row>-->
      <!--          <el-col :span="12">-->
      <!--            <el-form-item label="结算方名称" prop="settlementId" required>-->
      <!--              <Settlement v-model="model.settlementId"/>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="12">-->
      <!--            <el-form-item label="父级车场" prop="parentParkingId">-->
      <!--              <SuggestParking-->
      <!--                  v-model="model.parentParkingId"-->
      <!--                  placeholder="请选择父级车场"-->
      <!--              />-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </easy-card>-->
      <el-button class="xxd-button_save" type="primary" @click="save">保存
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { getRegionList } from "@/api/user";
import Location from "@/components/Location";
// import Settlement from "@/components/Settlement";
// import SuggestParking from "@/components/SuggestParking";
import {
  // createAndUpdateParkingLotInfo,
  doUploadImage,
  getParkingImage,
  createParkingLotInfo,
  updateParkingLotInfo,
} from "@/api/parking";

export default {
  name: "add-parking-basic",
  components: {
    Location,
    // Settlement,
    // SuggestParking,
  },

  props: {
    parkingModelJSON: String,
  },

  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, value } = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({ regionId: regionId })
            .then(({ returnObject }) => {
              console.log('getRegionList:', returnObject)
              const nodes = returnObject.regionResultVoList.map(item => ({
                value: item.id,
                label: item.name,
                leaf: item.level == 3,
              })
              )
              resolve(nodes)
            })
        }
      },
      isLoading: false,
      rules: {
        name: [{ required: true, message: "停车场名称不能为空" }],
        shortName: [{ required: true, message: "停车场简称不能为空" }],
        address: [{ required: true, message: "详细地址不能为空" }],
        parkingLocation: [{ required: true, message: "车场经纬度不能为空" }],
        parkingspaceNumber: [{ required: true, message: "泊位个数不能为空" }],
        parkingLotType: [{ required: true, message: "请选择停车场类型" }],
        // chargeType: [{required: true, message: "请选择充电类型"}],
        // isVisibleOnLine: [{required: true, message: "请选择是否可见"}],
        // settlementId: [{required: true, message: "请选择结算方"}],
        plandefaultAttachmentId: [
          { required: true, message: "车场平面图不能为空" },
        ],
        sceneDefaultAttachmentId: [
          { required: true, message: "车场实景图不能为空" },
        ],
      },
      // 平面图列表
      graphFileList: [],
      // 实景图列表
      realFileList: [],
      // 数据模型
      model: {
        id: "",
        parkingName: "",
        parkingNickName: "",
        regionId: "530129", // 先写死
        address: "",
        longitude: "",
        latitude: "",
        parkingLocation: "",
        parkingSpaceCount: "",
        isVisibleOnLine: "0",
        parkingType: "1",
        parkingService: "",
        chargeType: "1",
        parkingManager: "",
        managerMobile: "",
        ocrType: "1",
        unlicensedCarIntoType: "",
        custorm: "",
        deployMode: "",
        managementCompany: "",
        onlineParkingOrder: "",
        plandefaultAttachmentId: "",
        sceneDefaultAttachmentId: "",
        settlementId: "",
        parentParkingId: "",
        isUpload: "0",
        noDriveIn:"0",
        noDriveOut:"0",
        unPayDriveOut:"0",
        vehicleDriveIn:"0",
        motionType:"0",
        
      },
    };
  },

  watch: {
    parkingModelJSON(newVal) {
      console.log(newVal)
      if (newVal !== "") {
        const returnObject = JSON.parse(newVal);

        this.model = returnObject
        this.model.parkingLotType = this.model.parkingLotType.toString()
        this.model.parkingLocation = returnObject.longitude + "," + returnObject.latitude
        this.model.regionId = [this.model.provinceId, this.model.cityId, this.model.regionId]

        this.$refs.regionId.panel.activePath = [];
        this.$refs.regionId.panel.loadCount = 0;
        this.$refs.regionId.panel.lazyLoad();
        // cs.panel.activePath=[];
        // cs.panel.loadCount=0;
        // cs.panel.lazyLoad();

        // this.model = {
        //   id: returnObject.id,
        //   parkingName: returnObject.name,
        //   parkingNickName: returnObject.shortName,
        //   regionId: returnObject.regionId, // 先写死
        //   address: returnObject.address,
        //   longitude: returnObject.longitude,
        //   latitude: returnObject.latitude,
        //   parkingLocation: returnObject.longitude + "," + returnObject.latitude,
        //   parkingspaceNumber: returnObject.parkingspaceNumber,
        //   // isVisibleOnLine: !isNaN(returnObject.isVisibleOnLine)
        //   //     ? returnObject.isVisibleOnLine.toString()
        //   //     : returnObject.isVisibleOnLine,
        //   parkingType: !isNaN(returnObject.parkingLotType)
        //       ? returnObject.parkingLotType.toString()
        //       : returnObject.parkingLotType,
        //   // parkingService: returnObject.parkingService,
        //   // chargeType: !isNaN(returnObject.ifChargingPile)
        //   //     ? returnObject.ifChargingPile.toString()
        //   //     : returnObject.ifChargingPile,
        //   // parkingManager: returnObject.linkMan,
        //   // managerMobile: returnObject.linkTel,
        //   // ocrType: returnObject.ocrType,
        //   // unlicensedCarIntoType: returnObject.unlicensedCarIntoType,
        //   // custorm: returnObject.serviceTel,
        //   // deployMode: returnObject.deployMode,
        //   // managementCompany: returnObject.managementCompany,
        //   // onlineParkingOrder: returnObject.onlineParkingOrder,
        //   parkingPlanGraphImages:
        //       returnObject.plandefaultAttachmentId !== ""
        //           ? JSON.parse(returnObject.plandefaultAttachmentId)
        //           : [],
        //   parkingRealPictures:
        //       returnObject.sceneDefaultAttachmentId !== ""
        //           ? JSON.parse(returnObject.sceneDefaultAttachmentId)
        //           : [],
        //   // settlementId: returnObject.settlementCompanyId,
        //   // parentParkingId: returnObject.parentId,
        //   // // 0-运营,1-机关事业单位,2-联网
        //   // motionType: 0,
        //   // isUpload: returnObject.isUpload || "0",
        // };

        // 获取平面列表
        this.getAllGraphImages([this.model.plandefaultAttachmentId]);
        this.getAllRealImages([this.model.sceneDefaultAttachmentId]);
      }
    },
  },

  methods: {
    // 车场平面图删除
    onGraphImageRemove() {
      this.model.plandefaultAttachmentId = '';
    },

    onRealImageRemove() {
      this.model.sceneDefaultAttachmentId = '';
    },

    // 批量获取平面图列表
    getAllGraphImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getParkingImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.graphFileList = res;
      });
    },
    // 获取车场实景图列表
    getAllRealImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getParkingImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.realFileList = res;
      });
    },
    async getParkingImage(attachmentId) {
      const res = await getParkingImage(attachmentId);
      if (res && res.code === 30 && res.result) {
        return {
          url: res.returnObject,
          id: attachmentId,
        };
      }
    },
    onLocationMakeSure(point) {
      this.model.parkingLocation = point;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传车场平面图
    async doGraphImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000 * 5;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过5M! 请重新上传");
        return;
      }
      const formData = new FormData();
      formData.append('picIo', file.file);
      formData.append('name', file.file.name);
      const res = await doUploadImage(formData);
      if (res && res.code === 30 && res.result) {
        this.model.plandefaultAttachmentId = res.returnObject.id;
      }
    },
    // 上传实景平面图
    async doRealImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000 * 5;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过5M! 请重新上传");
        return;
      }
      const formData = new FormData();
      formData.append('picIo', file.file);
      formData.append('name', file.file.name);
      const res = await doUploadImage(formData);
      if (res && res.code === 30 && res.result) {
        this.model.sceneDefaultAttachmentId = res.returnObject.id
      }
    },

    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const location = this.model.parkingLocation.split(",");
          debugger
          if (this.model.regionId.length === 3) {
            this.model.regionId = this.model.regionId[2]
          }
          this.model.longitude = location[0];
          this.model.latitude = location[1];
          // this.model.motionType = this.$route.query.motionType;
          this.isLoading = true;
          if (this.model.id === '') {
            const res = await createParkingLotInfo(this.model);
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "车场添加成功!",
                type: "success",
              });
              this.$emit("success", res.returnObject);
              sessionStorage.setItem("save_parkinglot_success", "2");
            }
          } else {
            const res = await updateParkingLotInfo(this.model);
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "车场编辑成功!",
                type: "success",
              });
              this.$emit("success", res.returnObject);
              sessionStorage.setItem("save_parkinglot_success", "2");
            }
          }

        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.add-parking-basic {
  background: white;
  border-radius: 5px;
  padding: 18px;
}
.tool-font{
  font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 16px;
}
</style>
